import { Container, Row, Col, Tab, Nav } from "react-bootstrap";

import { VideoCard } from "./VideoCard";

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import img2037 from "../assets/img/photo/new_photo/IMG_2037.PNG";
import img2039 from "../assets/img/photo/new_photo/IMG_2039.PNG";
import img2040 from "../assets/img/photo/new_photo/IMG_2040.PNG";
import img2041 from "../assets/img/photo/new_photo/IMG_2041.PNG";
import img2042 from "../assets/img/photo/new_photo/IMG_2042.PNG";
import img2044 from "../assets/img/photo/new_photo/IMG_2044.PNG";
import img2045 from "../assets/img/photo/new_photo/IMG_2045.PNG";
import img4281 from "../assets/img/photo/new_photo/IMG_4821.png";


import video1 from "../assets/video/DSC_0001.mp4";
import img_video1 from "../assets/img/photo/thumbnail/thumb_DSC_0001.png";

import video2 from "../assets/video/DSC_0002.mp4";
import img_video2 from "../assets/img/photo/thumbnail/thumb_DSC_0002.png";

import video3 from "../assets/video/IMG_4968.mp4";
import img_video3 from "../assets/img/photo/thumbnail/thumb_IMG_4968.png";

import video4 from "../assets/video/IMG_4969.mp4";
import img_video4 from "../assets/img/photo/thumbnail/thumb_IMG_4969.png";

import video5 from "../assets/video/IMG_4970.mp4";
import img_video5 from "../assets/img/photo/thumbnail/thumb_IMG_4970.png";

import video6 from "../assets/video/DSC_0003.mp4";
import img_video6 from "../assets/img/photo/thumbnail/thumb_DSC_0003.png";

import video7 from "../assets/video/DSC_0005.mp4";
import img_video7 from "../assets/img/photo/thumbnail/thumb_DSC_0005.png";

import video8 from "../assets/video/DSC_0006.mp4";
import img_video8 from "../assets/img/photo/thumbnail/thumb_DSC_0006.png";

import video9 from "../assets/video/DSC_0008.mp4";
import img_video9 from "../assets/img/photo/thumbnail/thumb_DSC_0008.png";

import video10 from "../assets/video/DSC_0027.mp4";
import img_video10 from "../assets/img/photo/thumbnail/thumb_DSC_0027.png";

import "animate.css";

export const Projects = () => {
  const images = [
    {
      original: img2037,
      thumbnail: img2037
    },
    {
      original: img4281,
      thumbnail: img4281
    },
    {
      original: img2039,
      thumbnail: img2039
    },
    {
      original: img2040,
      thumbnail: img2040
    },
    {
      original: img2041,
      thumbnail: img2041
    },
    {
      original: img2042,
      thumbnail: img2042
    },
    {
      original: img2044,
      thumbnail: img2044
    },
    {
      original: img2045,
      thumbnail: img2045
    },
    {
      original: img_video3,
      thumbnail: img_video3,
      embedUrl: video3,
      renderItem: VideoCard.bind(this),
    },
    {
      original: img_video4,
      thumbnail: img_video4,
      embedUrl: video4,
      renderItem: VideoCard.bind(this),
    },
    {
      original: img_video5,
      thumbnail: img_video5,
      embedUrl: video5,
      renderItem: VideoCard.bind(this),
    },



  ];

  const videos = [
    {
      original: img_video1,
      thumbnail: img_video1,
      embedUrl: video1,
      renderItem: VideoCard.bind(this),
    },
    {
      original: img_video2,
      thumbnail: img_video2,
      embedUrl: video2,
      renderItem: VideoCard.bind(this),
    },
    {
      original: img_video6,
      thumbnail: img_video6,
      embedUrl: video6,
      renderItem: VideoCard.bind(this),
    },
    {
      original: img_video7,
      thumbnail: img_video7,
      embedUrl: video7,
      renderItem: VideoCard.bind(this),
    },
    {
      original: img_video8,
      thumbnail: img_video8,
      embedUrl: video8,
      renderItem: VideoCard.bind(this),
    },
    {
      original: img_video9,
      thumbnail: img_video9,
      embedUrl: video9,
      renderItem: VideoCard.bind(this),
    },
    {
      original: img_video10,
      thumbnail: img_video10,
      embedUrl: video10,
      renderItem: VideoCard.bind(this),
    }
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>

            
                <div >
                  <Tab.Container id="projects-tabs" defaultActiveKey="photo">
                    <Nav
                      variant="pills"
                      className="nav-pills mb-5 justify-content-center align-items-center"
                      id="pills-tab"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="photo">Фото/Видео</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="tth">
                          Технические характеристики
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="creator">Производство</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content
                      id="slideInUp"
                    >
                      <Tab.Pane eventKey="photo" >
                        <ImageGallery 
                          items={images} 
                          showThumbnails={false} 
                          renderPlayPauseButton={() => ""}
                          />
                      </Tab.Pane>
                      <Tab.Pane eventKey="tth" style={{alignContent: 'center'}}>
                        <table className="table tth" style={{color : "white"}}>
                          <tr><td>Длина наибольшая</td><td>4,7 м</td></tr>
                          <tr><td>Длина габаритная</td><td>5,1 м</td></tr>
                          <tr><td>Ширина наибольшая</td><td>1,7 м</td></tr>
                          <tr><td>Ширина габаритная</td><td>1,8 м</td></tr>
                          <tr><td>Высота габаритная</td><td>1,05 м</td></tr>
                          <tr><td>Высота борта на миделе</td><td>0,7 м</td></tr>
                          <tr><td>Водоизмещение полное</td><td>680 кг</td></tr>
                          <tr><td>Масса снаряжённого катера без горючего</td><td>300 кг</td></tr>
                          <tr><td>Килеватость на транце</td><td>9 грд</td></tr>
                          <tr><td>Ёмкость бензобаков</td><td>66 л</td></tr>
                          <tr><td>Дальность плавания до полной выработки</td><td>380 км</td></tr>
                          <tr><td>Пассажировместимость</td><td>3+ чел</td></tr>
                          <tr><td>Скорость хода при максимальной мощности и 2 людьми на борту</td><td>36 км/ч</td></tr>
                          <tr><td>Тип двигателя</td><td>бензиновый, четырехтактный, карбюраторный</td></tr>
                          <tr><td>Мощность максимальная</td><td>21 кВт (28.5 л.с.)</td></tr>
                          <tr><td>Количество цилиндров</td><td>2 шт</td></tr>
                          <tr><td>Частота вращения коленчатого вала при максимально скорости движения</td><td>3290 об/мин.</td></tr>
                          <tr><td>Охлаждение двигателя</td><td>комбинированное, воздушно-масляное</td></tr>
                          <tr><td>Максимальный крутящий момент</td><td>не менее 48,3 Н (4,93 кг.)</td></tr>
                          <tr><td>Удельный расход топлива</td><td>370 г/Втч</td></tr>
                          <tr><td>Применяемое топливо</td><td>автомобильный бензин А-92-95</td></tr>
                          <tr><td>Рабочий объём цилиндров</td><td>0.75 л</td></tr>
                          <tr><td>Температура масла в двигателе</td><td>75-95 °C</td></tr>
                          <tr><td>Вес конвертированного двигателя</td><td>48 кг</td></tr>                          
                        </table>
                      </Tab.Pane>

                      <Tab.Pane eventKey="creator" >
                        <ImageGallery
                          items={videos}
                          renderPlayPauseButton={() => ""}
                          showThumbnails={false}
                          showFullscreenButton={false}
                        />
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
