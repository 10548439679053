import { useState, useEffect } from "react";
import { Navbar, Container } from "react-bootstrap";
import stars from "../assets/img/stars.png";
import { BrowserRouter as Router } from "react-router-dom";

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState("home");
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  return (
    <Router>
      <Navbar
        expand="md"
        className={scrolled ? "scrolled" : ""}
        style={{ height: "auto",width: "100vw", padding: "10px 0" }}
      >
        <Container
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          {/* Логотип */}
          <Navbar.Brand href="/" style={{ flexGrow: 1 }}>
            <img src={stars} alt="" style={{ width: "20vw" }} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          {/* Меню */}
          <Navbar.Collapse id="basic-navbar-nav">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
                width: "auto",
                flex: 1, // добавлено, чтобы меню занимало всю оставшуюся ширину
                '@media (max-width: 480px)': {
                  gap: '0px', // убираем gap при ширине экрана меньше 420px
                }
              }}
            >
              <a
                href="#home"
                className={
                  activeLink === "home" ? "active navbar-link" : "active navbar-link"
                }
                onClick={() => onUpdateActiveLink("home")}
              >
                Главная
              </a>
              <a
                href="#projects"
                className={
                  activeLink === "projects"
                    ? "active navbar-link"
                    : "active navbar-link"
                }
                onClick={() => onUpdateActiveLink("projects")}
              >
                Описание
              </a>
              <a
                href="#contact"
                className={
                  activeLink === "contact"
                    ? "active navbar-link"
                    : "active navbar-link"
                }
                onClick={() => onUpdateActiveLink("contact")}
              >
                Как с нами связаться
              </a>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Router>
  );
};
