// import Card from "react-bootstrap/Card";
// import ReactPlayer from "react-player";
import { MediaPlayer, MediaProvider } from "@vidstack/react";
import {
  defaultLayoutIcons,
  DefaultVideoLayout,
} from "@vidstack/react/player/layouts/default";

export const VideoCard = (cardItem) => {
  return (
    <>
      <style>{`.vds-time-group {
        display : none;
      }`}</style>
      <MediaPlayer src={cardItem.embedUrl} preload="auto">
        <MediaProvider />
        <DefaultVideoLayout
          thumbnails={cardItem.thumbnail}
          icons={defaultLayoutIcons}
        />
      </MediaPlayer>
    </>
  );
};
